import { rest } from 'msw';
import { ModelFeedback } from 'src/types';
import { feedbackBaseUrl } from 'src/store/constants';
import { MOCK_AWS_BEARER_TOKEN } from './mockedConstants';

export const feedbackApiMock = [
  rest.post<ModelFeedback>(
    `${feedbackBaseUrl}/internal_feedback`,
    async (req, res, ctx) => {
      const authorizationHeader = req.headers.get('Authorization');
      if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
        return res(ctx.status(200));
      } else {
        return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
      }
    },
  ),
];
