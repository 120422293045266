import { TaskSkill } from './api';

export type PlanSectionType = 'free' | 'pro';

export interface PaymentPlanOption {
  lookup_key: string;
  amount: number;
  isDefault?: boolean;
  tasksCount?: number;
  isAnnual?: boolean;
}

export interface PaymentsActivity {
  skill: TaskSkill;
  task_hash: string;
  created_at: string;
  cost: number;
}

export interface PaymentsActivityColumn {
  header: string;
  accessor: keyof PaymentsActivity;
  format?: (value: number | string) => string;
}

export enum PaymentPlanInterval {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export interface PaymentsSubscriptionInfo {
  inception_timestamp: string;
  is_cancelled: boolean;
  curr_period_start_timestamp: string;
  curr_period_end_timestamp: string;
  interval: PaymentPlanInterval;
  price_info: {
    amount: number;
    currency: string;
    lookup_key: string;
    credit: number;
  };
}
