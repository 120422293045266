import { DEFAULT_AGENT } from 'src/constants';
import {
  Message,
  ApiTaskSelectable,
  TaskSkill,
  TaskState,
  MessageChannel,
  TaskChannel,
  ConversationRole,
} from 'src/types';

export const DEFAULT_MOCKED_USER_ID = 'DEFAULT_MOCKED_USER_ID';
export const DEFAULT_MOCKED_TASK_ID = 'DEFAULT_MOCKED_TASK_ID';
export const DEFAULT_MOCKED_MESSAGE_ID = 'DEFAULT_MOCKED_MESSAGE_ID';

export const DEFAULT_MOCKED_CHAT_MESSAGE: Message = {
  user_id: DEFAULT_MOCKED_USER_ID,
  message_id: 'default_message_id',
  timestamp: '2023-07-01T14:00:00',
  from_user_id: 'from_user_id',
  to_user_id: DEFAULT_MOCKED_USER_ID,
  channel: MessageChannel.WEB_APP,
  content: 'Test message 1',
  role: ConversationRole.USER,
};

export const DEFAULT_MOCKED_TASK_BY_ID: ApiTaskSelectable = {
  task_id: DEFAULT_MOCKED_TASK_ID,
  task_subject: 'Test Subject Ninja Tech AI',
  user_id: DEFAULT_MOCKED_USER_ID,
  skill: TaskSkill.SCHEDULING,
  state: TaskState.IN_PROGRESS,
  channel: TaskChannel.WEB_APP,
  agent_id: DEFAULT_AGENT.user_id,
  created_at: '2023-07-12T09:00:00.000Z',
  conversation: {
    user_id: DEFAULT_MOCKED_USER_ID,
    messages: [DEFAULT_MOCKED_CHAT_MESSAGE],
  },
  updated_at: '2023-07-12T09:00:00.000Z',
};

export const MOCK_AWS_BEARER_TOKEN = 'ninja_aws_bearer_token';
