import { rest } from 'msw';
import { tokenBaseUrl } from 'src/store/constants';
import { MOCK_AWS_BEARER_TOKEN } from './mockedConstants';

export const tokenApiMock = [
  rest.get(`${tokenBaseUrl}/msft/get-speech-token`, (req, res, ctx) => {
    const authorizationHeader = req.headers.get('Authorization');
    if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
      return res(ctx.status(200), ctx.json({ token: 'test', region: 'test' }));
    } else {
      return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
    }
  }),
];
