/**
 * Generate backend types by runnig `npm run generate-client` which
 * in its turn will also load backend models to src/types/models.
 */
export type { AiContent } from './models/AiContent';
export type { AiResult } from './models/AiResult';
export type { ApiMinimumTask } from './models/ApiMinimumTask';
export type { ApiTask } from './models/ApiTask';
export type { ApiUser } from './models/ApiUser';
export { AvatarVideoPresetName } from './models/AvatarVideoPresetName';
export { AvatarVideosStatus } from './models/AvatarVideosStatus';
export type { AvatarVideos } from './models/AvatarVideos';
export { ConversationRole } from './models/ConversationRole';
export { ErrorPayload } from './models/ErrorPayload';
export { MessageChannel as CombusMessageChannel } from './combus/models/MessageChannel';
export type { Action } from './models/Action';
export { ActionStatue } from './models/ActionStatue';
export type { ApiTaskList } from './models/ApiTaskList';
export type { ApiUserList } from './models/ApiUserList';
export type { CalendarCard } from './models/CalendarCard';
export type { CalendarCardEvent } from './models/CalendarCardEvent';
export type { CodeContent } from './models/CodeContent';
export type { Choice } from './models/Choice';
export type { Choices } from './models/Choices';
export type { ChoicesCard } from './models/ChoicesCard';
export { CodeTaskCreationCard } from './models/CodeTaskCreationCard';
export type { Conversation } from './models/Conversation';
export { ConversationState } from './models/ConversationState';
export { CommonInputTypes } from './models/CommonInputTypes';
export type { CurrencyAmount } from './models/CurrencyAmount';
export { Currency } from './models/Currency';
export type { CreateAvatarVideoRequest } from './models/CreateAvatarVideoRequest';
export type { DatetimeRangeList } from './models/DatetimeRangeList';
export type { Duration } from './models/Duration';
export { DebugMetadata } from './models/DebugMetadata';
export type { EmailCard } from './models/EmailCard';
export type { EmailAddress as EmailCollaborator } from './models/EmailAddress';
export { FeedbackType } from './models/FeedbackType';
export type { Guest } from './models/Guest';
export type { LlmSettings } from './models/LlmSettings';
export { LlmResource } from './models/LlmResource';
export type { Meeting } from './models/Meeting';
export { MeetingInputTypes } from './models/MeetingInputTypes';
export type { Message } from './models/Message';
export { MessageType } from './models/MessageType';
export { MessageChannel } from './models/MessageChannel';
export type { MessageContext } from './models/MessageContext';
export type { MessageMetadataResearchMetrics } from './models/MessageMetadataResearchMetrics';
export type { MessageMetadataResearchReferenceCode } from './models/MessageMetadataResearchReferenceCode';
export type { MessageMetadataResearchReferenceLLM } from './models/MessageMetadataResearchReferenceLLM';
export type { MessageMetadataResearchReferencesImageVideo } from './models/MessageMetadataResearchReferencesImageVideo';
export type { MessageMetadataResearchReferencesWebPage } from './models/MessageMetadataResearchReferencesWebPage';
export type { MessageMetadataResearchStatusUpdate } from './models/MessageMetadataResearchStatusUpdate';
export type { MessageMetadataResearchSummary } from './models/MessageMetadataResearchSummary';
export type { MessageMetadataSchedulerMetrics } from './models/MessageMetadataSchedulerMetrics';
export type { NegativeMessageFeedbackRequest } from './models/NegativeMessageFeedbackRequest';
export type { NegativeTaskFeedbackRequest } from './models/NegativeTaskFeedbackRequest';
export type { Negotiation } from './models/Negotiation';
export { NinjaEventTypes } from './combus/models/NinjaEventTypes';
export type { TextInput } from './models/TextInput';
export { OperationType } from './models/OperationType';
export type { PatchApiTask } from './models/PatchApiTask';
export type { ProposalConfirmationCard } from './models/ProposalConfirmationCard';
export type { PDUMessage } from './combus/models/PDUMessage';
export type { ProgressUpdate } from './models/ProgressUpdate';
export type { PositiveMessageFeedbackRequest } from './models/PositiveMessageFeedbackRequest';
export type { PositiveTaskFeedbackRequest } from './models/PositiveTaskFeedbackRequest';
export type { ResearchCard } from './models/ResearchCard';
export { ReservationDetails } from './models/ReservationDetails';
export type { ReservationLog } from './models/ReservationLog';
export type { ResearchCardData } from './models/ResearchCardData';
export type { ResearchData } from './models/ResearchData';
export type { ResearchTaskPayload } from './models/ResearchTaskPayload';
export { ResearchTaskPayloadKind } from './models/ResearchTaskPayloadKind';
export type { ResearchDataReferenceCode } from './models/ResearchDataReferenceCode';
export type { ResearchDataReferenceVideo } from './models/ResearchDataReferenceVideo';
export type { ResearchDataReferenceImage } from './models/ResearchDataReferenceImage';
export type { ResearchDataReferenceWebPage } from './models/ResearchDataReferenceWebPage';
export type { ResearchDataReferenceLLM } from './models/ResearchDataReferenceLLM';
export type { ResearchResponseMetrics } from './models/ResearchResponseMetrics';
export type { SchedulerCreateEventMetrics } from './models/SchedulerCreateEventMetrics';
export { SchedulingPayload } from './models/SchedulingPayload';
export type { SchedulingRequest } from './models/SchedulingRequest';
export { SortOrder } from './models/SortOrder';
export type { SearchResult as ResearchSearchResult } from './models/SearchResult';
export type { DeepResearchResult } from './models/DeepResearchResult';
export type { SearchWebPageReference } from './models/SearchWebPageReference';
export type { SearchImageReference } from './models/SearchImageReference';
export type { SearchVideoReference } from './models/SearchVideoReference';
export { SupportTicketType } from './models/SupportTicketType';
export type { SupportTicketRequest } from './models/SupportTicketRequest';
export { TaskChannel } from './models/TaskChannel';
export { TaskSkill } from './models/TaskSkill';
export { TaskState } from './models/TaskState';
export type { TestPayload } from './models/TestPayload';
export { TextInputCard } from './models/TextInputCard';
export { UserType } from './models/UserType';
export type { UserSavingInsightsSettings } from './models/UserSavingInsightsSettings';
export type { UserSettings } from './models/UserSettings';
export { UserCostSavingDuration } from './models/UserCostSavingDuration';
export type { UserResearchSettings } from './models/UserResearchSettings';
export type { WebSearchSettings } from './models/WebSearchSettings';
export type { ResearchTaskCreationCard } from './models/ResearchTaskCreationCard';
export type { SchedulerCreationCard } from './models/SchedulerCreationCard';
export type { CreateMeetingCard } from './models/CreateMeetingCard';
export type { SingleSelect } from './models/SingleSelect';
export type { SingleSelectOption } from './models/SingleSelectOption';
export type { Span } from './models/Span';
export type { Attendee } from './models/Attendee';
export type { WebSearchUserSettings } from './models/WebSearchUserSettings';
export type { CoderSourcesUserSettings } from './models/CoderSourcesUserSettings';
export { CommunicationToneID } from './models/CommunicationToneID';
export { UserTierStatus } from './models/UserTierStatus';
export { IDP } from './models/IDP';
export type { LocationRequest } from './models/LocationRequest';
export { LocationSource } from './models/LocationSource';

export enum ResearchAi {
  OPEN_AI = 'OPEN_AI',
  ANTHROPIC = 'ANTHROPIC',
  GOOGLE = 'GOOGLE',
}

export type AiReference = {
  source: ResearchAi;
  content: string;
};

export type MessageDebugData = {
  x_trace_id?: string;
};

export type ResearchDataReferenceText = {
  url: string;
  kind: string;
};

export type ModelFeedbackDialogue = {
  content: string;
  role: string;
};

export type ModelFeedback = {
  user_input: string;
  model_input: string;
  whole_dialogue: Array<ModelFeedbackDialogue>;
  model_name: string;
  model_intent: string;
  ui_environment: string;
  is_intent_correct?: boolean;
  correct_intent?: string;
  model_response: string;
  ideal_response?: string;
  additional_feedback?: string;
  user_name: string;
  timestamp: string;
  request_id: string;
  model_id: string;
  model_output: string;
  thought: string;
  label: string;
  input: string;
  reply: string;
  thumbs?: boolean;
  understanding?: number;
  helpfulness?: number;
  coherence?: number;
  completeness?: number;
  accuracy?: number;
  language_quality?: number;
  relevance?: number;
  user_experience?: number;
  style?: number;
  error_handling?: number;
};

export type ApiContext = {
  conversation_id?: string;
  task_id?: string;
};
