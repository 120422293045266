import { useCallback, useEffect, useState } from 'react';
import { useUpdateUserLocationMutation } from 'src/store/services';
import { LocationRequest } from 'src/types';
import { useConditionalInterval, useSession } from 'src/hooks';
import log from 'src/utils/logger';

export const DEFAULT_UPDATE_LOCATION_INTERVAL = 5 * 60 * 1000;

const USER_LOCATION_OPTIONS = {
  enableHighAccuracy: false,
  maximumAge: 1 * 60 * 60 * 10000,
  timeout: 1 * 60 * 1000,
};

export const useUserLocation = () => {
  const { appUser } = useSession();
  const [updateUserLocation] = useUpdateUserLocationMutation();

  const [hasLocationUpdateFailed, setHasLocationUpdateFailed] =
    useState<boolean>(false);

  const successCallback = useCallback(
    async (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      const body: LocationRequest = {
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      };

      try {
        await updateUserLocation({
          user_id: appUser.user_id,
          body,
        }).unwrap();
      } catch (error) {
        setHasLocationUpdateFailed(true);

        log.error('error', error);
      }
    },
    [appUser.user_id, updateUserLocation],
  );

  const errorCallback = useCallback(
    async (error: GeolocationPositionError | null) => {
      try {
        await updateUserLocation({
          user_id: appUser.user_id,
          body: {},
        }).unwrap();
      } catch (error) {
        // TODO(olha): add event and send to BE error type
        log.error('error', error);
      }

      setHasLocationUpdateFailed(true);
      // TODO(olha): add event and send to BE error type
      log.error('error', error);
    },
    [appUser.user_id, updateUserLocation],
  );

  const handleUpdateLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        USER_LOCATION_OPTIONS,
      );
    } else {
      errorCallback(null);
    }
  }, [successCallback, errorCallback]);

  const {
    startInterval: setLocationInterval,
    stopInterval: stopLocationInterval,
  } = useConditionalInterval(
    handleUpdateLocation,
    DEFAULT_UPDATE_LOCATION_INTERVAL,
  );

  const handleSetLocationInterval = useCallback(() => {
    setLocationInterval();
  }, [setLocationInterval]);

  useEffect(() => {
    if (hasLocationUpdateFailed) {
      stopLocationInterval();
    }
  }, [hasLocationUpdateFailed, stopLocationInterval]);

  return {
    updateLocation: handleUpdateLocation,
    setLocationInterval: handleSetLocationInterval,
  };
};
