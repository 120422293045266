import {
  addConversationChatMessageChunk,
  addConversationChatMessageContentChunk,
  addConversationChatMessageFooter,
  addMessageToConversationChat,
  addMessageToTaskChat,
  addTaskChat,
  addTaskChatMessageContentChunk,
  appendConversationChatMessage,
  appendTaskChatMessage,
  replaceConversationChatMessage,
  replaceDefaultChat,
  replaceTaskChatMessage,
  setCurrentConversationId,
  setSocketConnected,
  showAccessRequestOnSchedulerTask,
  updateConversationChatMessage,
  updateConversationsState,
  updateTaskChatState,
  useAppDispatch,
  useAppSelector,
  userChatsStateThreadsStatusRegistry,
} from 'src/store';
import { useEffect, useRef } from 'react';
import ReconnectingWebSocket from 'src/utils/ws';
import { combusWSBaseUrl } from 'src/store/constants';
import { get_access_token, isBeta, isGamma, isJsonString } from 'src/utils';
import {
  BannerType,
  ConversationRole,
  isApiTaskSelectable,
  isConversation,
  isMessage,
  isMessageChunk,
  isMessageFooter,
  isPartialApiTask,
  isPartialConversation,
  isPDUMessage,
  Message,
  MessageType,
  NinjaEventTypes,
  OperationType,
} from 'src/types';
import {
  setBannerType,
  setUserAppVersion,
  setUserStatus,
  updateRenewEarlyStatus,
  updateUserTier,
} from 'src/store/slices/sessionSlice';
import log from 'src/utils/logger';
import { walletApi, WalletTags } from 'src/store/services';
import { UserTier } from 'src/types/models/UserTier';
import { UserTierStatus } from 'src/types/models/UserTierStatus';
import { UserEarlyRenewalStatus } from 'src/types/models/UserEarlyRenewalStatus';
import { toast } from 'react-toastify';

export const useSocket = (user_id: string, agent_id: string) => {
  const webSocketRef = useRef<ReconnectingWebSocket | null>(null);
  const dispatch = useAppDispatch();

  const threadsStatusRegistry = useAppSelector(
    userChatsStateThreadsStatusRegistry,
  );

  useEffect(() => {
    if (user_id && combusWSBaseUrl) {
      // user_id must have been changed, reopen connection with new token and user_id
      if (webSocketRef.current !== null) {
        webSocketRef.current?.close();
        webSocketRef.current = null;
      }

      webSocketRef.current = new ReconnectingWebSocket(
        combusWSBaseUrl,
        async () => {
          const token = await get_access_token();
          return (combusWSBaseUrl &&
            combusWSBaseUrl.indexOf('ws://localhost') >= 0) ||
            !token
            ? [user_id]
            : ['ninja.ws', token, user_id];
        },
        {
          debug: isBeta() || isGamma(),
          maxReconnectionDelay: 30000,
          shouldPing: true,
        },
      );

      webSocketRef.current.onopen = () => {
        log.debug('nj socket: onopen');
        dispatch(setSocketConnected(true));
      };

      webSocketRef.current.onclose = () => {
        log.debug('nj socket: onclose');
        dispatch(setSocketConnected(false));
      };

      webSocketRef.current.onerror = () => {
        log.error('nj socket: onerror');
        dispatch(setSocketConnected(false));
      };

      webSocketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (isPDUMessage(data)) {
          const { event_type, payload } = data;
          const apiPayload = JSON.parse(payload);

          const conversationId = apiPayload.conversation_id;
          const ignoreMessages =
            threadsStatusRegistry[conversationId]?.ignoreMessages;

          if (ignoreMessages) {
            return;
          }

          switch (event_type) {
            case NinjaEventTypes.NEW_TASK: {
              if (isApiTaskSelectable(apiPayload)) {
                dispatch(addTaskChat(apiPayload));
                dispatch(showAccessRequestOnSchedulerTask(apiPayload));
              } else {
                log.error(`Incorrect data type for ApiTask ${payload}`);
              }
              break;
            }
            case NinjaEventTypes.NEW_CONVERSATION: {
              if (isConversation(apiPayload)) {
                dispatch(replaceDefaultChat(apiPayload));
                dispatch(setCurrentConversationId(apiPayload.conversation_id));
              } else {
                log.error(`Incorrect data type for Conversation ${payload}`);
              }
              break;
            }
            case NinjaEventTypes.NEW_MESSAGE: {
              if (isMessage(apiPayload)) {
                switch (apiPayload.operation_type) {
                  case OperationType.APPEND:
                    if (apiPayload.task_id) {
                      dispatch(appendTaskChatMessage(apiPayload));
                    } else {
                      dispatch(appendConversationChatMessage(apiPayload));
                    }
                    break;
                  case OperationType.REPLACE:
                    if (apiPayload.task_id) {
                      dispatch(replaceTaskChatMessage(apiPayload));
                    } else {
                      dispatch(replaceConversationChatMessage(apiPayload));
                    }
                    break;
                  case OperationType.UPDATE:
                    if (!apiPayload.task_id) {
                      dispatch(updateConversationChatMessage(apiPayload));
                    }
                    break;
                  default:
                    if (apiPayload.task_id) {
                      dispatch(addMessageToTaskChat(apiPayload));
                    } else {
                      dispatch(addMessageToConversationChat(apiPayload));
                    }
                    break;
                }
              } else {
                log.error(`Incorrect data type for Message type ${payload}`);
              }
              break;
            }
            case NinjaEventTypes.NEW_STREAMABLE_MESSAGE_HEADER: {
              if (!isMessage(apiPayload)) {
                log.error(`Incorrect data type for Message type ${payload}`);
                break;
              }

              if (apiPayload.message_type === MessageType.CONVERSATION) {
                dispatch(addMessageToConversationChat(apiPayload));
                break;
              }

              if (apiPayload.task_id) {
                // TODO(olha) For now we suppose if operation_type === REPLACE,
                dispatch(replaceTaskChatMessage(apiPayload));
              } else {
                // TODO(olha) For now we suppose if operation_type === UPDATE,
                dispatch(updateConversationChatMessage(apiPayload));
              }
              break;
            }

            case NinjaEventTypes.NEW_STREAMABLE_MESSAGE_CHUNK: {
              if (!isMessageChunk(apiPayload)) {
                log.error(
                  `Incorrect data type for MessageChunk type ${payload}`,
                );
                break;
              }

              if (
                apiPayload.message_type === MessageType.CONVERSATION &&
                !apiPayload.task_id
              ) {
                dispatch(addConversationChatMessageChunk(apiPayload));
                break;
              }

              if (!isJsonString(apiPayload.content)) {
                if (apiPayload.task_id) {
                  dispatch(addTaskChatMessageContentChunk(apiPayload));
                } else {
                  dispatch(addConversationChatMessageContentChunk(apiPayload));
                }

                break;
              }

              const { content, ...restChunk } = apiPayload;

              const messagePayload = JSON.parse(content);

              const chunkMessage: Message = {
                content: '',
                ...restChunk,
                payload: messagePayload,
                // TODO(olha): there is the better solution to rewrite all slices for using Partial<Message> instead of whole Message
                user_id: user_id,
                to_user_id: user_id,
                from_user_id: agent_id,
                role: ConversationRole.AGENT,
              };

              if (!isMessage(chunkMessage)) {
                log.error(
                  `Incorrect payload message type in MessageChunk ${payload}`,
                );
                break;
              }

              if (chunkMessage.task_id) {
                switch (chunkMessage.operation_type) {
                  case OperationType.APPEND:
                    dispatch(appendTaskChatMessage(chunkMessage));
                    break;

                  case OperationType.REPLACE:
                    dispatch(replaceTaskChatMessage(chunkMessage));
                    break;

                  default:
                    dispatch(addMessageToTaskChat(chunkMessage));
                    break;
                }
              } else {
                switch (chunkMessage.operation_type) {
                  case OperationType.APPEND:
                    dispatch(appendConversationChatMessage(chunkMessage));
                    break;

                  case OperationType.REPLACE:
                    dispatch(replaceConversationChatMessage(chunkMessage));
                    break;

                  case OperationType.UPDATE:
                    dispatch(updateConversationChatMessage(chunkMessage));
                    break;

                  default:
                    break;
                }
              }
              break;
            }

            case NinjaEventTypes.NEW_STREAMABLE_MESSAGE_FOOTER: {
              if (!isMessageFooter(apiPayload)) {
                log.error(
                  `Incorrect data type for MessageFooter type ${payload}`,
                );
                break;
              }

              if (
                apiPayload.message_type === MessageType.CONVERSATION ||
                apiPayload.message_type === MessageType.TASK_CREATED
              ) {
                dispatch(addConversationChatMessageFooter(apiPayload));
                break;
              }

              if (!isMessage(apiPayload)) {
                log.error(
                  `Incorrect data type for Message in MessageFooter ${payload}`,
                );
                break;
              }
              if (apiPayload.task_id) {
                dispatch(replaceTaskChatMessage(apiPayload));
              } else {
                dispatch(replaceConversationChatMessage(apiPayload));
              }
              break;
            }

            case NinjaEventTypes.UPDATE_TASK: {
              if (isPartialApiTask(apiPayload)) {
                dispatch(updateTaskChatState(apiPayload));
              } else {
                log.error(
                  `Arrived data is not of the Partial<ApiTask> type ${payload}`,
                );
              }
              break;
            }
            case NinjaEventTypes.UPDATE_CONVERSATION: {
              if (isPartialConversation(apiPayload)) {
                dispatch(updateConversationsState(apiPayload));
              } else {
                log.error(
                  `Arrived data is not of the Conversation type ${payload}`,
                );
              }
              break;
            }
            case NinjaEventTypes.UPDATE_USER_STATUS: {
              dispatch(setUserStatus(apiPayload));
              break;
            }
            case NinjaEventTypes.UPDATE_APP_VERSION: {
              dispatch(setUserAppVersion(apiPayload));
              break;
            }
            case NinjaEventTypes.NOTIFY_USER: {
              if (
                apiPayload.payload_type === 'user_balance_notification' &&
                apiPayload.task_balance !== undefined
              ) {
                const task_balance = apiPayload.task_balance;
                dispatch(
                  walletApi.util.updateQueryData(
                    'getUserTaskQuotaInfo',
                    { user_id: user_id },
                    () => {
                      return { count: task_balance };
                    },
                  ),
                );
              } else if (
                apiPayload.payload_type === 'user_balance_notification' &&
                apiPayload.budget_balance !== undefined
              ) {
                if (!!apiPayload.low_budget) {
                  dispatch(setBannerType(BannerType.LOW_CREDITS));
                } else {
                  dispatch(setBannerType(null));
                }
                dispatch(
                  walletApi.util.updateQueryData(
                    'getUserBudgetQuotaInfo',
                    { user_id: user_id },
                    (data) => {
                      return {
                        ...data,
                        amount: apiPayload.budget_balance,
                        is_low_balance: !!apiPayload.low_budget,
                      };
                    },
                  ),
                );
              }
              break;
            }
            case NinjaEventTypes.UPDATE_USER: {
              if (apiPayload) {
                if (!!apiPayload.new_early_renewal_status) {
                  if (
                    apiPayload.new_early_renewal_status ===
                    UserEarlyRenewalStatus.RENEWED
                  ) {
                    toast('Plan successfully renewed');
                    dispatch(
                      walletApi.util.invalidateTags([
                        WalletTags.UserSubscriptionInfo,
                      ]),
                    );
                  }
                  if (
                    apiPayload.new_early_renewal_status ===
                    UserEarlyRenewalStatus.FAILED
                  ) {
                    toast.error("Plan wasn't renewed");
                  }
                  dispatch(
                    updateRenewEarlyStatus({
                      renew_early_pending_subscription: undefined,
                    }),
                  );
                } else {
                  dispatch(updateUserTier(apiPayload));
                  if (
                    apiPayload.new_tier === UserTier.PAID &&
                    apiPayload.new_tier_status === UserTierStatus.QUOTA_EXCEEDED
                  )
                    dispatch(setBannerType(BannerType.INSUFFICIENT_CREDITS));
                }
              }
              break;
            }
            default:
              log.error('ERROR: Unknown data type: ', apiPayload);
          }
        }
      };
    }

    return () => {
      webSocketRef.current?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, combusWSBaseUrl]);

  return {
    openSocket: webSocketRef.current,
  };
};
