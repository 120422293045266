import Ninja from 'src/images/ninja-loader/ninja.png';

type NinjaLoaderProps = {
  content: string;
};

const IMG_SIZE = 160;

export const NinjaLoader = ({ content }: NinjaLoaderProps) => {
  return (
    <div className="nj-ninja-loader">
      <div className="nj-ninja-loader--images">
        <img
          src={Ninja}
          alt="Ninja"
          className="nj-ninja-loader--image"
          width={IMG_SIZE}
          height={IMG_SIZE}
        />
        <div className="nj-ninja-loader--spinner"></div>
      </div>
      <div className="nj-ninja-loader--content">{content}</div>
    </div>
  );
};
