import { datadogRum } from '@datadog/browser-rum';
import TagManager from 'react-gtm-module';
import {
  GTMVariables,
  GTMEvent,
  ApiTaskSelectable,
  TaskSkill,
  TaskState,
  IDP,
} from 'src/types';
import { UserTier } from 'src/types/models/UserTier';

const PRODUCTION_ENVIRONMENT = 'production';

type ActionMetadata = {
  [key: string]: string | number | boolean | undefined;
};

const DEFAULT_EVENTS: GTMVariables = {
  message_id: '',
  task_id: '',
  task_state: '',
  task_skill: '',
};

export const logAction = (action: string, metadata?: ActionMetadata) => {
  datadogRum.addAction(action, metadata);
};

export const initializeGTM = () => {
  if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION_ENVIRONMENT) {
    return;
  }

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
  };

  TagManager.initialize(tagManagerArgs);
};

const getTierGTMTitle = (tier?: UserTier) => {
  switch (tier) {
    case UserTier.PAID:
      return 'PRO';

    default:
      return tier?.toLocaleUpperCase() || '';
  }
};

export const addUserIdToGTMDataLayer = ({
  user_id,
  tier_id,
  login_type,
}: {
  user_id: string;
  tier_id?: UserTier;
  login_type?: IDP;
}) => {
  if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION_ENVIRONMENT) {
    return;
  }

  const tagManagerArgs = {
    dataLayer: {
      user_id,
      tier_id: getTierGTMTitle(tier_id),
      login_type,
      event: GTMEvent.INITIALIZATION_FROM_PORTAL,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const addSourcesToGTMDataLayer = ({
  calendar_access = false,
  contacts_access = false,
}: {
  calendar_access?: boolean;
  contacts_access?: boolean;
}) => {
  if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION_ENVIRONMENT) {
    return;
  }

  const tagManagerArgs = {
    dataLayer: {
      calendar_access,
      contacts_access,
      event: GTMEvent.INITIALIZATION_ACCESS_STATE,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const sendGTMEvent = (
  event: GTMEvent,
  variables: GTMVariables = {},
): void => {
  if (process.env.REACT_APP_ENVIRONMENT !== PRODUCTION_ENVIRONMENT) {
    return;
  }

  const tagManagerArgs = {
    dataLayer: {
      event,
      ...Object.fromEntries(
        Object.keys(DEFAULT_EVENTS).map((key: keyof GTMVariables) => [
          key,
          variables[key] ?? '',
        ]),
      ),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

const getSkillGTMTitle = (skill?: TaskSkill) => {
  switch (skill) {
    case TaskSkill.CHITCHAT:
      return 'Advisor';

    case TaskSkill.RESEARCH:
      return 'Researcher';

    case TaskSkill.CODING:
      return 'Coder';

    case TaskSkill.SCHEDULING:
      return 'Scheduler';

    default:
      return '';
  }
};

export const taskGTMEvent = (task: Partial<ApiTaskSelectable>) => {
  const variables: GTMVariables = {
    task_id: task.task_id,
    task_state: task.state,
    task_skill: getSkillGTMTitle(task.skill),
  };

  if (task.skill === TaskSkill.CHITCHAT) {
    sendGTMEvent(GTMEvent.TASK_EVENT, {
      ...variables,
      task_state: TaskState.DONE,
    });
  }

  if (
    !task.state ||
    (task.state === TaskState.BLOCKED && !task.requires_attention) ||
    (task.state === TaskState.DONE && !task.requires_attention) ||
    !task.skill
  ) {
    return;
  }

  sendGTMEvent(GTMEvent.TASK_EVENT, variables);
};
