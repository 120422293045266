// TODO(olha): add all routes
export enum AppRoutes {
  HOME = '/',
  CALENDAR = '/calendar',
  SUBSCRIPTION = '/subscription',
}

export enum SubscriptionNestedRoutes {
  MANAGE_PLAN = 'manage-plan',
  BILLING = 'billing',
  ACTIVITY = 'activity',
}

export interface QueryParams {
  session_id: string;
  query: string;
}
