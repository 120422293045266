import { rest } from 'msw';
import { CurrentUser } from 'src/types';
import { tasksBaseUrl } from 'src/store/constants';
import { MOCK_AWS_BEARER_TOKEN } from './mockedConstants';

export const MOCKED_CONTACT: CurrentUser = {
  _id: '44664728-914e-4c05-bdf2-d171ad4edcb4',
  name: 'Ninja Test1',
  first_name: 'Ninja',
  last_name: 'Test1',
  email: 'test1@ninjatech.ai',
  settings: {
    research: {
      llms: ['gpt-4'],
    },
  },
};

export const MOCKED_CONTACT_2: CurrentUser = {
  _id: '44664728-914e-4c05-bdf2-d171ad4edcb5',
  name: 'Ninja Test2',
  first_name: 'Ninja',
  last_name: 'Test2',
  email: 'test2@ninjatech.ai',
  settings: {
    research: {
      llms: ['gpt-4'],
    },
  },
};

export const MOCKET_CONTACT_3: CurrentUser = {
  _id: '44664728-914e-4c05-bdf2-d171ad4edcb6',
  name: 'Ninja Test3',
  first_name: 'Ninja',
  last_name: 'Test3',
  email: 'test3@ninjatech.ai',
  settings: {
    research: {
      llms: ['gpt-4'],
    },
  },
};

export const contactsApiMock = [
  rest.get(`${tasksBaseUrl}/users`, (req, res, ctx) => {
    const authorizationHeader = req.headers.get('Authorization');
    if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
      return res(
        ctx.status(200),
        ctx.json({
          data: [MOCKED_CONTACT, MOCKED_CONTACT_2, MOCKET_CONTACT_3],
        }),
      );
    } else {
      return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
    }
  }),
];
