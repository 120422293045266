import { rest } from 'msw';
import { CurrentUser } from 'src/types';
import { UpdateUserRequest } from '../usersApi';
import { tasksBaseUrl } from 'src/store/constants';
import {
  DEFAULT_MOCKED_USER_ID,
  MOCK_AWS_BEARER_TOKEN,
} from './mockedConstants';

export const MOCKED_USER_ID_WITH_METADATA = 'MOCKED_USER_ID_WITH_METADATA';
export const MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX =
  'MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX';
export const NOT_FOUND_USER_ID = 'NOT_FOUND_USER_ID';

export const DEFAULT_MOCKED_USER: CurrentUser = {
  _id: DEFAULT_MOCKED_USER_ID,
  name: 'John Smith',
  first_name: 'John',
  last_name: 'Smith',
  email: 'js@ninjatech.ai',
  settings: {
    research: {
      llms: ['gpt-4'],
    },
  },
};

export const MOCKED_USER_WITH_METADATA: CurrentUser = {
  _id: MOCKED_USER_ID_WITH_METADATA,
  name: 'John Smith',
  email: 'js@ninjatech.ai',
  first_name: 'John',
  last_name: 'Smith',
  settings: {
    research: {
      llms: ['gpt-4'],
    },
  },
};

export const MOCKED_USER_WITH_MAX_CAMERA_INDEX: CurrentUser = {
  _id: MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX,
  name: 'John Smith',
  first_name: 'John',
  last_name: 'Smith',
  email: 'js@ninjatech.ai',
  settings: {
    research: {
      llms: ['gpt-4'],
    },
  },
};

export const usersApiMock = [
  rest.get(
    `${tasksBaseUrl}/users/${DEFAULT_MOCKED_USER_ID}`,
    (req, res, ctx) => {
      const authorizationHeader = req.headers.get('Authorization');
      if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
        return res(ctx.status(200), ctx.json({ data: DEFAULT_MOCKED_USER }));
      } else {
        return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
      }
    },
  ),

  rest.get(
    `${tasksBaseUrl}/users/${MOCKED_USER_ID_WITH_METADATA}`,
    (req, res, ctx) => {
      const authorizationHeader = req.headers.get('Authorization');
      if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
        return res(
          ctx.status(200),
          ctx.json({ data: MOCKED_USER_WITH_METADATA }),
        );
      } else {
        return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
      }
    },
  ),

  rest.get(
    `${tasksBaseUrl}/users/${MOCKED_USER_ID_WITH_MAX_CAMERA_INDEX}`,
    (req, res, ctx) => {
      const authorizationHeader = req.headers.get('Authorization');
      if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
        return res(
          ctx.status(200),
          ctx.json({ data: MOCKED_USER_WITH_MAX_CAMERA_INDEX }),
        );
      } else {
        return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
      }
    },
  ),

  rest.get(`${tasksBaseUrl}/users/${NOT_FOUND_USER_ID}`, (req, res, ctx) => {
    const authorizationHeader = req.headers.get('Authorization');
    if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
      return res(
        ctx.status(500),
        ctx.json({ message: 'Value is not a valid uuid' }),
      );
    } else {
      return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
    }
  }),

  rest.patch<UpdateUserRequest>(
    `${tasksBaseUrl}/users/${DEFAULT_MOCKED_USER_ID}`,
    async (req, res, ctx) => {
      const authorizationHeader = req.headers.get('Authorization');
      if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
        const { body } = await req.json<UpdateUserRequest>();
        const { persona, settings } = body;

        if (persona?.avatar_id) {
          return res(
            ctx.status(200),
            ctx.json({
              ...DEFAULT_MOCKED_USER,
              persona: {
                avatar_id: persona.avatar_id,
              },
            }),
          );
        }

        if (settings?.camera_angle_setting?.camera_index) {
          return res(
            ctx.status(200),
            ctx.json({
              ...DEFAULT_MOCKED_USER,
              settings: {
                ...DEFAULT_MOCKED_USER.settings,
                camera_angle_setting: {
                  camera_index: settings?.camera_angle_setting?.camera_index,
                },
              },
            }),
          );
        }
        return res(ctx.status(200), ctx.json(DEFAULT_MOCKED_USER));
      } else {
        return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
      }
    },
  ),
];
