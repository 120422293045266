import dayjs from 'dayjs';
import {
  ResearchAi,
  Message,
  MessageType,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  AiContent,
  ResearchCardData,
  CodeTaskCreationCard,
  CodeSourceTypes,
  CodeContent,
  CalendarCardEvent,
} from 'src/types';
import { researchSourcesSet } from 'src/components/FlatAppearance/components/ThreadResearchCard/constants/researchSourcesSet';
import { sourcesSet } from 'src/components/FlatAppearance/components/ThreadCodeCard/components/ThreadCodeSources/constants/sourcesSet';
import { flattenObject } from './conversation';

/**
 * getSchedulerCopyTemplate() returns a copyable string template for a scheduler task creation card.
 * @param message Message
 * @returns string
 */
export function getSchedulerCopyTemplate(message: Message): string {
  if (message.message_type !== MessageType.SCHEDULER_TASK_CREATION_CARD) {
    return '';
  }

  const { calendar_card, task_query, details } =
    message?.payload as SchedulerCreationCard;
  const { summary = 'Schedule', dates } = calendar_card || {};

  const template: string[] = [];
  const taskQuery =
    message?.refined_query || message.clean_query || task_query || '';

  template.push(`I have set up a task to follow up on: "${taskQuery}"`);
  if (details) template.push(details || '');

  if (dates) {
    template.push(summary);
    template.push(`Calendar: https://calendar.google.com/calendar/u/0/r`);

    const sortedDates = Object.keys(dates).sort((a, b) =>
      dayjs(a).diff(dayjs(b)),
    );

    if (sortedDates.length > 0) {
      for (const sortedDate of sortedDates) {
        template.push(`\n${dayjs(sortedDate).format('MMM DD')}`);
      }

      template.push(dayjs(sortedDates[0]).format('dddd, MMM D, YYYY'));

      const sortedByDateEvents = sortedDates.reduce(
        (acc, date) => {
          const sortedEvents = dates[date]
            .slice()
            .sort((a, b) => dayjs(a.start).diff(dayjs(b.start)));
          acc[date] = sortedEvents;
          return acc;
        },
        {} as Record<string, Array<CalendarCardEvent>>,
      );

      const currentEvents = sortedByDateEvents[sortedDates[0]];

      for (const currentEvent of currentEvents) {
        const { start, end, is_all_day, title, is_recurring, html_link } =
          currentEvent;
        const timeRange = is_all_day
          ? 'All day'
          : `${dayjs(start).format('h:mm A')}-${dayjs(end).format('h:mm A')}`;

        template.push(
          `${timeRange} \t ${
            is_recurring ? 'recurring' : ''
          } \t ${title} \n ${html_link}`,
        );
      }
    }
  }

  return template.join('\n\n');
}

/**
 * getCodeCopyTemplate() returns a copyable string template for a code task creation card.
 * @param message Message
 * @returns string
 */
export function getCodeCopyTemplate(message: Message): string {
  if (message.message_type !== MessageType.CODE_TASK_CREATION_CARD) {
    return '';
  }

  const { code_card, task_query } = message?.payload as CodeTaskCreationCard;

  const template: string[] = [];
  const taskQuery =
    message?.refined_query || message.clean_query || task_query || '';

  template.push(`I have set up a task to follow up on: "${taskQuery}"\n`);

  const { code_snippets } = code_card?.data || {};

  if (code_snippets) {
    const sources = Object.entries(code_snippets)
      .map(([key, value]) => {
        const { raw_text, model } = value as CodeContent;
        const name =
          model?.human_friendly_name ||
          sourcesSet[key as CodeSourceTypes].title;

        return {
          key: name,
          content: raw_text,
          order: sourcesSet[key as CodeSourceTypes].order,
        };
      })
      .slice()
      .sort((a, b) => a.order - b.order);

    for (const value of sources) {
      template.push(flattenObject(value));
    }
  }

  return template.join('\n\n');
}

/**
 * getResearchTemplate() returns a copyable string template for a research task creation card.
 * @param message Message
 * @returns string
 */
export function getResearchCopyTemplate(message: Message): string {
  if (message.message_type !== MessageType.RESEARCH_TASK_CREATION_CARD) {
    return '';
  }

  const { research_card, task_query } =
    message?.payload as ResearchTaskCreationCard;

  const template: string[] = [];
  const taskQuery =
    message?.refined_query || message.clean_query || task_query || '';

  template.push(`I have set up a task to follow up on: "${taskQuery}"\n`);

  if (!research_card) {
    return template.join('\n');
  }

  const { search_result, deep_research_result, generative_ai_result } =
    research_card.data as ResearchCardData;

  const { web_page_references, image_references, video_references } =
    search_result || {};

  if (search_result) {
    template.push('Ninja LLM');
    template.push('Real-time web search');
    template.push(search_result?.summary ?? '');
  }

  if (web_page_references) {
    template.push('References');
    template.push(flattenObject({ web_page_references: web_page_references }));
  }

  if (deep_research_result) {
    template.push('Deep Research');
    template.push(deep_research_result?.summary ?? '');
  }

  if (image_references || video_references) {
    const combined_references = [
      ...(video_references || []),
      ...(image_references || []),
    ];

    template.push('Videos & Images');
    if (Array.isArray(combined_references) && combined_references.length > 0) {
      template.push(
        flattenObject({ combined_references: combined_references }),
      );
    }
  }

  if (generative_ai_result) {
    const { references } = generative_ai_result || {};

    if (references) {
      const sources = Object.entries(references)
        .map(([key, value]) => {
          const { model, content } = value as AiContent;
          const name =
            model?.human_friendly_name ||
            researchSourcesSet[key as ResearchAi].title;

          return {
            key: name,
            content,
            order: researchSourcesSet[key as ResearchAi].order,
          };
        })
        .slice()
        .sort((a, b) => a.order - b.order);

      const otherSources = sources.map((source) => source.key);
      template.push('From other sources:', otherSources.join(', '));

      template.push(generative_ai_result?.summary ?? '');

      for (const value of sources) {
        template.push(flattenObject(value));
      }
    }
  }

  return template.join('\n\n');
}
