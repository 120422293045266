import { useEffect, useRef } from 'react';
import {
  DEFAULT_AVATAR_MALE_VOICE_ID,
  DEFAULT_AVATAR_VOICE_ID,
} from 'src/constants';
import { useTextToSpeach, useAvatarPreferences } from 'src/hooks';

/**
 * Hook for text-to-speech in chat applications, considering user's avatar gender preference for voice selection.
 * It can start or stop speech synthesis for messages, allowing interruption for new messages.
 *
 * Usage:
 * handleTextToSpeech(text: string): Initiates or cancels speech synthesis for the given text.
 * If synthesis is in progress for the same text, it's cancelled; otherwise, it starts a new synthesis.
 *
 * Note: This hook should be integrated at the level of the message list
 * to allow for seamless speech synthesis control across message interactions.
 */
export const useHandleSpeechSynthesis = () => {
  const synthesizerInProgressRef = useRef<boolean>(false);
  const synthesizingTextRef = useRef<string | null>(null);

  const { synthesizerInProgress, synthesizeVoice, cancelSynthesizer } =
    useTextToSpeach();
  const { selectedAvatarGender, avatarVoiceID } = useAvatarPreferences();

  useEffect(() => {
    synthesizerInProgressRef.current = synthesizerInProgress;
  }, [synthesizerInProgress]);

  const handleTextToSpeech = (text: string) => {
    if (
      synthesizerInProgressRef?.current &&
      text === synthesizingTextRef.current
    ) {
      cancelSynthesizer();
      synthesizingTextRef.current = null;
    } else {
      const voiceID = avatarVoiceID
        ? avatarVoiceID
        : selectedAvatarGender === 'female'
          ? DEFAULT_AVATAR_VOICE_ID
          : DEFAULT_AVATAR_MALE_VOICE_ID;

      synthesizingTextRef.current = text;
      synthesizeVoice(voiceID, text);
    }
  };

  return { onTextToSpeech: handleTextToSpeech };
};
