import { setupServer } from 'msw/node';
import { usersApiMock } from './usersApiMock';
import { tasksApiMock } from './tasksApiMock';
import { feedbackApiMock } from './feedbackApiMock';
import { contactsApiMock } from './contactsApiMock';
import { tokenApiMock } from './tokenApiMock';

const handlers = usersApiMock.concat(
  tasksApiMock,
  feedbackApiMock,
  contactsApiMock,
  tokenApiMock,
);

export const mockServer = setupServer(...handlers);
