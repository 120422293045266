import { rest } from 'msw';
import {
  Message,
  TaskSkill,
  TaskState,
  MessageChannel,
  TaskChannel,
  ConversationRole,
} from 'src/types';
import { tasksBaseUrl } from 'src/store/constants';
import { DEFAULT_MOCKED_USER_ID, DEFAULT_MOCKED_TASK_BY_ID, MOCK_AWS_BEARER_TOKEN } from './mockedConstants';

const MOCKED_CHAT_MESSAGE: Message = {
  user_id: DEFAULT_MOCKED_USER_ID,
  message_id: 'message_id_2',
  timestamp: '2023-06-01T16:00:00.000Z',
  from_user_id: DEFAULT_MOCKED_USER_ID,
  to_user_id: 'to_user_id',
  channel: MessageChannel.WEB_APP,
  content: 'Test message 2',
  role: ConversationRole.USER,
};

export const MOCKED_TASK_BY_ID = {
  task_id: 'task_id',
  task_subject: 'Test Task Subject',
  user_id: DEFAULT_MOCKED_USER_ID,
  skill: TaskSkill.SCHEDULING,
  state: TaskState.DONE,
  channel: TaskChannel.WEB_APP,
  created_at: '2023-06-01T19:00:00.000Z',
  messages: [MOCKED_CHAT_MESSAGE],
  updated_at: '2023-06-01T19:00:00.000Z',
};

export const MOCKED_TASKS_BY_USER_ID = [
  MOCKED_TASK_BY_ID,
  DEFAULT_MOCKED_TASK_BY_ID,
];

export const tasksApiMock = [
  rest.get(
    `${tasksBaseUrl}/users/${DEFAULT_MOCKED_USER_ID}/tasks`,
    (req, res, ctx) => {
      const authorizationHeader = req.headers.get('Authorization');
      if (authorizationHeader === `Bearer ${MOCK_AWS_BEARER_TOKEN}`) {
        res(ctx.status(200), ctx.json({ data: MOCKED_TASKS_BY_USER_ID }));
      } else {
        return res(ctx.status(401), ctx.json({ error: 'Unauthorized' }));
      }
    },
  ),
];
